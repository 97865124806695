export default function GetUserInfo(basedata) {
    const results = {};

    var vUserID = localStorage.getItem("User_ID");
    console.log("User ID Token", vUserID);
    const Content = new URLSearchParams();
    Content.append("id_token", localStorage.getItem("User_ID"));
    Content.append("client_id", process.env.VUE_APP_Channel_ID);

    const uri = "https://api.line.me/oauth2/v2.1/verify";
    fetch(uri, {
        method: "POST",
        body: Content,
    })
        .then((res) => {
            console.log("res type:", res);

            return res.json(); // 使用 json() 可以得到 json 物件
        })
        .then((result) => {
            console.log("result type:", result); //
            console.log("Token:", result.access_token);

            //存 local storage
            localStorage.setItem("user_name", result.name);
            localStorage.setItem("user_subid", result.sub);
            localStorage.setItem("user_picture", result.picture);

            basedata.pic_url = result.picture;
            console.log('>>>  result.picture=', result.picture);
            console.log('>>> basedata.pic_url=', basedata.pic_url);

            basedata.line_name = result.name;
            basedata.line_userid = result.sub;


        });

    return results;
}