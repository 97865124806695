export default function GetAccess() {
    const results = {};

    let url =
        "https://access.line.me/oauth2/v2.1/authorize?response_type=code&client_id=" +
        process.env.VUE_APP_Channel_ID +
        "&redirect_uri=" +
        process.env.VUE_APP_Callback_URL +
        "&state=12345abcde&scope=openid%20profile&nonce=09876xyz";

    window.location = url;

    return results;
}