<template>
  <div>
    <nav aria-label="breadcrumb">
      <ol class="breadcrumb">
        <li class="breadcrumb-item">
          <router-link to="/" class="navClass">Home</router-link>
        </li>
        <li class="breadcrumb-item active" aria-current="page">Line Test</li>
      </ol>
    </nav>

    <p>
      <img
        :src="basedata.btn_login_pic"
        @mouseover="btnLoginOver"
        @mouseout="btnLoginOut"
        @mousedown="btnLoginDown"
        @mouseup="btnLoginUp"
        @click="DoLineAccess"
        v-if="basedata.line_userid == undefined || basedata.line_userid == ''"
      />
    </p>
    <p>
      <button
        @click="LineLogout"
        class="btn btn-danger"
        v-if="basedata.line_userid != '' && basedata.line_userid != undefined"
      >
        登出
      </button>
    </p>

    <p>{{ basedata.line_name }} 您好！</p>
    <p><img style="width: 300px" :src="basedata.pic_url" /></p>
    <p>My Callback URL:{{ myBackUrl }}</p>
    <p>MyTitle:{{ myTitle }}</p>
  </div>
</template>

<script>
import { reactive, onMounted } from "vue";
import { useRoute } from "vue-router";
import GetAccess from "@/composables/Line/Access";
import GetToken from "@/composables/Line/GetToken";

export default {
  name: "LineTest",

  setup() {
    let myBackUrl = process.env.VUE_APP_Callback_URL;
    let myTitle = process.env.VUE_APP_Title;
    let myId = process.env.VUE_APP_Channel_ID;

    const basedata = reactive({
      MoonList: [],
      selected: "",
      token: "",
      line_userid: localStorage.getItem("user_subid"),
      line_name: localStorage.getItem("user_name"),
      pic_url: localStorage.getItem("user_picture"),
      btn_login_pic: "/images/btn_login_base.png",
    });
    const route = useRoute();

    function LineLogout() {
      localStorage.removeItem("user_subid");
      localStorage.removeItem("user_name");
      localStorage.removeItem("user_picture");
      localStorage.removeItem("User_ID");
      localStorage.removeItem("LineToken");

      basedata.line_userid = "";
      basedata.line_name = "";
      basedata.pic_url = "";
    }

    function btnLoginOver() {
      console.log("Mouse over");
      basedata.btn_login_pic = "/images/btn_login_hover.png";
    }

    function btnLoginOut() {
      console.log("Mouse out");
      basedata.btn_login_pic = "/images/btn_login_base.png";
    }

    function btnLoginDown() {
      basedata.btn_login_pic = "/images/btn_login_press.png";
    }

    function btnLoginUp() {
      basedata.btn_login_pic = "/images/btn_login_hover.png";
    }

    function DoLineAccess() {
      GetAccess();
    }

    function btnLineLogin() {
      GetAccess();
    }

    function GetUserName() {
      var vUserID = localStorage.getItem("User_ID");
      console.log("User ID Token", vUserID);
      const Content = new URLSearchParams();
      Content.append("id_token", localStorage.getItem("User_ID"));
      Content.append("client_id", process.env.VUE_APP_Channel_ID);

      const uri = "https://api.line.me/oauth2/v2.1/verify";
      fetch(uri, {
        method: "POST",
        body: Content,
      })
        .then((res) => {
          console.log("res type:", res);

          return res.json(); // 使用 json() 可以得到 json 物件
        })
        .then((result) => {
          console.log("result type:", result); //
          console.log("Token:", result.access_token);

          //存 local storage
          localStorage.setItem("user_name", result.name);
          localStorage.setItem("user_subid", result.sub);
          localStorage.setItem("user_picture", result.picture);

          //IMG
          basedata.pic_url = result.picture;

          alert(`${result.name} 您好!`);
        });
    }

    onMounted(() => {
      let code = route.query.code;

      //如果已經取得 Access Token ，直接讀取 User Token
      if (code != undefined) {
        GetToken(code, basedata);
      }
    });

    return {
      DoLineAccess,
      myBackUrl,
      myTitle,
      myId,
      GetUserName,
      basedata,
      btnLoginOver,
      btnLoginOut,
      btnLoginDown,
      btnLoginUp,
      btnLineLogin,
      LineLogout,
    };
  },
};
</script>

<style>
</style>