import GetUserInfo from "@/composables/Line/UserInfo";
export default function GetToken(code, basedata) {
    const results = {};

    let ContentType = "application/x-www-form-urlencoded;";

    const Content = new URLSearchParams();
    Content.append("grant_type", "authorization_code");
    Content.append("code", code);
    Content.append("redirect_uri", process.env.VUE_APP_Callback_URL);
    Content.append("client_id", process.env.VUE_APP_Channel_ID);
    Content.append("client_secret", process.env.VUE_APP_Channel_secret);

    console.log("Content:", Content);

    const uri = "https://api.line.me/oauth2/v2.1/token";
    fetch(uri, {
        method: "POST",
        headers: {
            "Content-Type": ContentType,
        },
        body: Content,
    })
        .then((res) => {
            console.log("res type:", res);

            return res.json(); // 使用 json() 可以得到 json 物件
        })
        .then((result) => {
            console.log("result type:", result); //
            console.log("Token:", result.access_token);

            //Save Token
            localStorage.setItem("LineToken", result.access_token);
            localStorage.setItem("User_ID", result.id_token);

            console.log('直接去取得 UserInfo');
            //直接去取得 UserInfo
            GetUserInfo(basedata);

        });

    return results;
}